import React, { useState, useEffect, useCallback } from 'react';
import { X } from 'lucide-react';
import ImageDropzone from '../ImageDropzone/ImageDropzone';
import FightSponsor from '../FightSponsor/FightSponsor';
import './FightSettings.css';

const FightSettings = ({ isOpen, onClose, onSave, initialData, eventSponsors }) => {
  const [fightData, setFightData] = useState({
    type: '',
    weightClass: '',
    rounds: 3,
    roundDuration: '3.0',
    championship: 'Kein Titelkampf',
    isActive: false,
    isCancelled: false,
    event_sponsor_index: null,
    fighterRed: {
      firstName: '',
      lastName: '',
      nickname: '',
      team: '',
      weight: '',
      height: '',
      age: '',
      image: null,
      imageUrl: null,
      serverImageUrl: null,
      titles: '',
      statistics: {
        wins: 0,
        draws: 0,
        losses: 0
      }
    },
    fighterBlue: {
      firstName: '',
      lastName: '',
      nickname: '',
      team: '',
      weight: '',
      height: '',
      age: '',
      image: null,
      imageUrl: null,
      serverImageUrl: null,
      titles: '',
      statistics: {
        wins: 0,
        draws: 0,
        losses: 0
      }
    }
  });

  const objectUrls = [];

  useEffect(() => {
    if (initialData) {
      const getImageUrl = (image, imageUrl) => {
        if (image instanceof File) {
          const url = URL.createObjectURL(image);
          objectUrls.push(url);
          return url;
        } else {
          return imageUrl || null;
        }
      };

      const formattedData = {
        type: initialData.type || '',
        weightClass: initialData.weightClass || '',
        rounds: initialData.rounds || 3,
        roundDuration: String(initialData.roundDuration || '3.0'),
        championship: initialData.championship || 'NONE',
        isActive: Boolean(initialData.isActive),
        isCancelled: Boolean(initialData.isCancelled),
        event_sponsor_index: initialData.event_sponsor_index !== undefined ? 
        initialData.event_sponsor_index : null,
        fighterRed: {
          firstName: initialData.fighterRed?.firstName || '',
          lastName: initialData.fighterRed?.lastName || '',
          nickname: initialData.fighterRed?.nickname || '',
          team: initialData.fighterRed?.team || '',
          weight: initialData.fighterRed?.weight || '',
          height: initialData.fighterRed?.height || '',
          age: initialData.fighterRed?.age || '',
          image: initialData.fighterRed?.image || null,
          imageUrl: getImageUrl(initialData.fighterRed?.image, initialData.fighterRed?.imageUrl),
          serverImageUrl: initialData.fighterRed?.imageUrl || null,
          titles: initialData.fighterRed?.titles || '',
          statistics: {
            wins: Number(initialData.fighterRed?.statistics?.wins) || 0,
            draws: Number(initialData.fighterRed?.statistics?.draws) || 0,
            losses: Number(initialData.fighterRed?.statistics?.losses) || 0
          }
        },
        fighterBlue: {
          firstName: initialData.fighterBlue?.firstName || '',
          lastName: initialData.fighterBlue?.lastName || '',
          nickname: initialData.fighterBlue?.nickname || '',
          team: initialData.fighterBlue?.team || '',
          weight: initialData.fighterBlue?.weight || '',
          height: initialData.fighterBlue?.height || '',
          age: initialData.fighterBlue?.age || '',
          image: initialData.fighterBlue?.image || null,
          imageUrl: getImageUrl(initialData.fighterBlue?.image, initialData.fighterBlue?.imageUrl),
          serverImageUrl: initialData.fighterBlue?.imageUrl || null,
          titles: initialData.fighterBlue?.titles || '',
          statistics: {
            wins: Number(initialData.fighterBlue?.statistics?.wins) || 0,
            draws: Number(initialData.fighterBlue?.statistics?.draws) || 0,
            losses: Number(initialData.fighterBlue?.statistics?.losses) || 0
          }
        }
      };
      setFightData(formattedData);
    }

    return () => {
      objectUrls.forEach((url) => {
        URL.revokeObjectURL(url);
      });
    };
  }, [initialData]);

  const handleFighterChange = useCallback((side, field, value) => {
    setFightData(prev => ({
      ...prev,
      [`fighter${side}`]: {
        ...prev[`fighter${side}`],
        [field]: value
      }
    }));
  }, []);

  const handleImageCropped = useCallback((side, file) => {
    const imageUrl = URL.createObjectURL(file);
    handleFighterChange(side, 'image', file);
    handleFighterChange(side, 'imageUrl', imageUrl);
    handleFighterChange(side, 'serverImageUrl', null);
  }, [handleFighterChange]);

  const handleStatisticsChange = useCallback((side, field, value) => {
    setFightData(prev => ({
      ...prev,
      [`fighter${side}`]: {
        ...prev[`fighter${side}`],
        statistics: {
          ...prev[`fighter${side}`].statistics,
          [field]: parseInt(value) || 0
        }
      }
    }));
  }, []);

  const handleFightDataChange = useCallback((field, value) => {
    if (field === 'roundDuration') {
      const sanitizedValue = value.replace(',', '.');
      if (!isNaN(parseFloat(sanitizedValue)) || sanitizedValue === '' || sanitizedValue === '.') {
        setFightData(prev => ({
          ...prev,
          [field]: sanitizedValue
        }));
      }
    } else if (field === 'weightClass' || field === 'rounds') {
      setFightData(prev => ({
        ...prev,
        [field]: value === '' ? '' : parseInt(value)
      }));
    } else {
      setFightData(prev => ({
        ...prev,
        [field]: value
      }));
    }
  }, []);

  const formatDurationHint = (duration) => {
    if (!duration || isNaN(parseFloat(duration))) return '';
    const minutes = Math.floor(parseFloat(duration));
    const seconds = Math.round((parseFloat(duration) % 1) * 60);
    return `${minutes}:${String(seconds).padStart(2, '0')} min`;
  };

  const handleSponsorSelect = (index) => {
    setFightData(prev => ({
      ...prev,
      event_sponsor_index: prev.event_sponsor_index === index ? null : index
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const submissionData = {
      ...fightData,
      weightClass: parseInt(fightData.weightClass),
      rounds: parseInt(fightData.rounds),
      roundDuration: parseFloat(String(fightData.roundDuration).replace(',', '.')),
      championship: fightData.championship || 'Kein Titelkampf',
      fighterRed: {
        ...fightData.fighterRed,
        weight: parseFloat(fightData.fighterRed.weight),
        height: parseInt(fightData.fighterRed.height),
        age: parseInt(fightData.fighterRed.age),
        titles: String(fightData.fighterRed.titles).trim(),
        statistics: {
          wins: parseInt(fightData.fighterRed.statistics.wins),
          draws: parseInt(fightData.fighterRed.statistics.draws),
          losses: parseInt(fightData.fighterRed.statistics.losses)
        }
      },
      fighterBlue: {
        ...fightData.fighterBlue,
        weight: parseFloat(fightData.fighterBlue.weight),
        height: parseInt(fightData.fighterBlue.height),
        age: parseInt(fightData.fighterBlue.age),
        titles: String(fightData.fighterBlue.titles).trim(),
        statistics: {
          wins: parseInt(fightData.fighterBlue.statistics.wins),
          draws: parseInt(fightData.fighterBlue.statistics.draws),
          losses: parseInt(fightData.fighterBlue.statistics.losses)
        }
      }
    };

    onSave(submissionData);
  };

  const FighterForm = useCallback(({ side, fighter }) => (
    <div className={`fight-settings-fighter-form fight-settings-fighter-${side.toLowerCase()}`}>
      <h3>Kämpfer {side === 'Red' ? 'Rot' : 'Blau'}</h3>
  
      <div className="fight-settings-fighter-image">
        <label>Kämpferfoto</label>
        <ImageDropzone
          onImageCropped={(file) => handleImageCropped(side, file)}
          imageFile={fighter.image}
          previewUrl={fighter.imageUrl}
          aspectRatio={1}
          label="Kämpferfoto hochladen"
        />
      </div>
  
      <div className="fight-settings-form-grid">
        <div className="fight-settings-form-group">
          <label>Vorname</label>
          <input
            type="text"
            value={fighter.firstName}
            onChange={(e) => handleFighterChange(side, 'firstName', e.target.value)}
            required
          />
        </div>
        <div className="fight-settings-form-group">
          <label>Nachname</label>
          <input
            type="text"
            value={fighter.lastName}
            onChange={(e) => handleFighterChange(side, 'lastName', e.target.value)}
            required
          />
        </div>
        <div className="fight-settings-form-group">
          <label>Spitzname</label>
          <input
            type="text"
            value={fighter.nickname}
            onChange={(e) => handleFighterChange(side, 'nickname', e.target.value)}
          />
        </div>
        <div className="fight-settings-form-group">
          <label>Team</label>
          <input
            type="text"
            value={fighter.team}
            onChange={(e) => handleFighterChange(side, 'team', e.target.value)}
            required
          />
        </div>
        <div className="fight-settings-form-group">
          <label>Aktueller Titel (mit Komma trennen)</label>
          <input
            type="text"
            value={fighter.titles}
            onChange={(e) => handleFighterChange(side, 'titles', e.target.value)}
            placeholder="z.B. WBA Champion, European Champion"
          />
        </div>
        <div className="fight-settings-form-group">
          <label>Gewicht (kg)</label>
          <input
            type="number"
            value={fighter.weight}
            onChange={(e) => handleFighterChange(side, 'weight', e.target.value)}
            required
          />
        </div>
        <div className="fight-settings-form-group">
          <label>Größe (cm)</label>
          <input
            type="number"
            value={fighter.height}
            onChange={(e) => handleFighterChange(side, 'height', e.target.value)}
            required
          />
        </div>
        <div className="fight-settings-form-group">
          <label>Alter</label>
          <input
            type="number"
            value={fighter.age}
            onChange={(e) => handleFighterChange(side, 'age', e.target.value)}
            required
          />
        </div>
  
        <div className="fight-settings-statistics">
          <h4>Statistiken</h4>
          <div className="fight-settings-stats-grid">
            <div className="fight-settings-form-group">
              <label>Siege</label>
              <input
                type="number"
                value={fighter.statistics.wins}
                onChange={(e) => handleStatisticsChange(side, 'wins', e.target.value)}
                required
              />
            </div>
            <div className="fight-settings-form-group">
              <label>Unentschieden</label>
              <input
                type="number"
                value={fighter.statistics.draws}
                onChange={(e) => handleStatisticsChange(side, 'draws', e.target.value)}
                required
              />
            </div>
            <div className="fight-settings-form-group">
              <label>Niederlagen</label>
              <input
                type="number"
                value={fighter.statistics.losses}
                onChange={(e) => handleStatisticsChange(side, 'losses', e.target.value)}
                required
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ), [handleFighterChange, handleImageCropped, handleStatisticsChange]);

  if (!isOpen) return null;

  return (
    <div className="fight-settings-overlay">
      <div className="fight-settings-modal">
        <div className="fight-settings-header">
          <h2>{initialData ? 'Kampf bearbeiten' : 'Neuer Kampf'}</h2>
          <button className="fight-settings-close" onClick={onClose}>
            <X size={24} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="fight-settings-content">
          <div className="fight-settings-general">
            <h3>Kampf Einstellungen</h3>
            <div className="fight-settings-form-grid">
              <div className="fight-settings-form-group">
                <label>Kampfart</label>
                <select
                  value={fightData.type}
                  onChange={(e) => handleFightDataChange('type', e.target.value)}
                  required
                >
                  <option value="">Bitte wählen</option>
                  <option value="Kickboxen">Kickboxen</option>
                  <option value="Boxen">Boxen</option>
                  <option value="Mixed Martial Arts">Mixed Martial Arts</option>
                  <option value="K1">K1</option>
                </select>
              </div>
              <div className="fight-settings-form-group">
                <label>Gewichtsklasse (kg)</label>
                <input
                  type="number"
                  value={fightData.weightClass}
                  onChange={(e) => handleFightDataChange('weightClass', e.target.value)}
                  required
                />
              </div>
              <div className="fight-settings-form-group">
                <label>Runden</label>
                <input
                  type="number"
                  value={fightData.rounds}
                  onChange={(e) => handleFightDataChange('rounds', e.target.value)}
                  required
                  min="1"
                />
              </div>
              <div className="fight-settings-form-group">
                <label>Rundendauer (min)</label>
                <input
                  type="text"
                  value={fightData.roundDuration}
                  onChange={(e) => handleFightDataChange('roundDuration', e.target.value)}
                  required
                  pattern="^\d*[.,]?\d*$"
                  inputMode="decimal"
                  placeholder="3.5"
                  title="Geben Sie die Rundendauer in Minuten ein (z.B. 3.5 für 3:30)"
                />
                {fightData.roundDuration && (
                  <span className="fight-settings-duration-hint">
                    {formatDurationHint(fightData.roundDuration)}
                  </span>
                )}
              </div>
              <div className="fight-settings-form-group">
                <label>Meisterschaft</label>
                <select
                  value={fightData.championship}
                  onChange={(e) => handleFightDataChange('championship', e.target.value)}
                >
                  <option value="Kein Titelkampf">Kein Titelkampf</option>
                  <option value="Weltmeisterschaft">Weltmeisterschaft</option>
                  <option value="Europameisterschaft">Europameisterschaft</option>
                  <option value="Deutsche Meisterschaft">Deutsche Meisterschaft</option>
                  <option value="Landesmeisterschaft">Landesmeisterschaft</option>
                </select>
              </div>
            </div>

            {Array.isArray(eventSponsors) && eventSponsors.length > 0 && (
              <div className="fightsponsor-section">
                <h3 className="fightsponsor-title">Sponsor auswählen</h3>
                <div className="fightsponsor-grid">
                  {eventSponsors.map((sponsor, index) => (
                    <FightSponsor
                      key={index}
                      sponsor={sponsor}
                      index={index}
                      isSelected={fightData.event_sponsor_index === index}
                      onClick={handleSponsorSelect}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="fight-settings-fighters-container">
            <FighterForm side="Blue" fighter={fightData.fighterBlue} />
            <FighterForm side="Red" fighter={fightData.fighterRed} />
          </div>

          <div className="fight-settings-actions">
            <button type="button" className="fight-settings-cancel" onClick={onClose}>
              Abbrechen
            </button>
            <button type="submit" className="fight-settings-save">
              {initialData ? 'Speichern' : 'Kampf erstellen'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FightSettings;
