import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { Trash2, Calendar, Users } from 'lucide-react';
import './Events.css';
import { API_BASE_URL } from '../../config/api';

const Events = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);
  const { user } = useAuth();
  const isAdmin = user?.is_admin == 1;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/user/get-users.php`, {
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Failed to fetch users');
        }

        const data = await response.json();
        setUsers(data.users || []);
      } catch (err) {
        console.error('Error fetching users:', err);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/event/get-all.php`, {
          credentials: 'include'
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch events');
        }

        const data = await response.json();
        setEvents(data.events || []);
      } catch (err) {
        console.error('Error fetching events:', err);
        setError('Failed to load events. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const handleDelete = async (eventId, e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const response = await fetch(`${API_BASE_URL}/event/delete.php?id=${eventId}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to delete event');
      }

      const data = await response.json();
      if (data.message === 'Event erfolgreich gelöscht') {
        setEvents(events.filter(event => event.id !== eventId));
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (err) {
      setError(err.message || 'Failed to delete event. Please try again.');
    }
  };

  const renderCreateButton = () => {
    if (!isAdmin) return null;
    return (
      <Link to="/event-anlegen" className="create-event-button">
        <Calendar size={18} />
        <span>Event anlegen</span>
      </Link>
    );
  };

  const renderDeleteButton = (eventId) => {
    if (!isAdmin) return null;
    return (
      <button
        onClick={(e) => handleDelete(eventId, e)}
        className="events-delete-btn"
        aria-label="Delete event"
      >
        <Trash2 size={18} />
      </button>
    );
  };

  const renderEventUsers = (event) => {
    if (!event.assigned_users || event.assigned_users.length === 0) {
      return null;
    }

    const assignedUsernames = event.assigned_users
      .map(userId => users.find(u => Number(u.id) === Number(userId))?.username)
      .filter(username => username); // Remove undefined values

    if (assignedUsernames.length === 0) {
      return null;
    }

    return (
      <div className="events-item-users">
        <Users size={14} />
        <span>{assignedUsernames.join(', ')}</span>
      </div>
    );
  };

  if (loading) {
    return <div className="events-loading">Loading events...</div>;
  }

  if (error) {
    return <div className="events-error">{error}</div>;
  }

  return (
    <div className="events-page">
      <div className="events-container">
        <div className="events-header">
          <div className="events-header-content">
            <h1 className="events-title">Events</h1>
            {renderCreateButton()}
          </div>
        </div>
        
        <div className="events-list">
          {events.length === 0 ? (
            <div className="events-empty">Keine Events vorhanden</div>
          ) : (
            events.map(event => {
              const eventDate = new Date(event.date);
              return (
                <div key={event.id} className="events-item">
                  <Link to={`/event/bearbeiten/${event.id}`} className="events-item-link">
                    <div className="events-item-date">
                      <span className="events-date-month">
                        {format(eventDate, 'MMM', { locale: de }).toUpperCase()}
                      </span>
                      <span className="events-date-day">
                        {format(eventDate, 'dd', { locale: de })}
                      </span>
                    </div>
                    
                    <div className="events-item-main">
                      <h2 className="events-item-title">{event.name}</h2>
                      <div className="events-item-details">
                        <time className="events-item-time">
                          {format(eventDate, 'HH:mm', { locale: de })} Uhr
                        </time>
                        {renderEventUsers(event)}
                      </div>
                    </div>
                  </Link>
                  {renderDeleteButton(event.id)}
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default Events;