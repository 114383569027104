import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import './LoginModal.css';
import { API_BASE_URL } from '../../config/api';
import { useNavigate } from 'react-router-dom';

const LoginModal = ({ isOpen, onClose }) => {
    const navigate = useNavigate();
    const { login, isLoading } = useAuth();
    const [formData, setFormData] = useState({
      identifier: '',
      password: ''
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isResetMode, setIsResetMode] = useState(false);
    const [isResetSent, setIsResetSent] = useState(false);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setError('');
  
      try {
        await login(formData.identifier, formData.password);
        handleCloseModal();
        navigate('/events');
      } catch (error) {
        setError(error.message || 'Login-Fehler. Bitte erneut versuchen.');
      }
    };

    const handleResetSubmit = async (e) => {
      e.preventDefault();
      setError('');
      setSuccess('');

      try {
        const response = await fetch(`${API_BASE_URL}/auth/send-reset-token.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ identifier: formData.identifier }),
        });

        const data = await response.json();

        if (response.ok) {
          setSuccess(data.message);
          setIsResetSent(true);
        } else {
          setError(data.message);
        }
      } catch (error) {
        setError('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
      }
    };

    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    };

    const handleCloseModal = () => {
      setFormData({
        identifier: '',
        password: ''
      });
      setError('');
      setSuccess('');
      setShowPassword(false);
      setIsResetMode(false);
      setIsResetSent(false);
      onClose();
    };

    const switchToResetMode = (e) => {
      e.preventDefault();
      setIsResetMode(true);
      setError('');
      setSuccess('');
      setFormData({ ...formData, password: '' });
    };

    const switchToLoginMode = (e) => {
      e.preventDefault();
      setIsResetMode(false);
      setIsResetSent(false);
      setError('');
      setSuccess('');
    };

    if (!isOpen) return null;

    return (
      <div className="login-modal-overlay">
        <div className="login-modal-content">
          <div className="login-modal-glow" />
          <button className="login-modal-close" onClick={handleCloseModal}>
            <svg 
              width="24" 
              height="24" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
          
          <div className="login-modal-header">
            {isResetMode ? (
              <>
                <h2>Passwort zurücksetzen</h2>
                <p>
                  {isResetSent 
                    ? 'Überprüfen Sie Ihre E-Mails für weitere Anweisungen' 
                    : 'Geben Sie Ihren Benutzernamen oder Ihre E-Mail-Adresse ein'}
                </p>
              </>
            ) : (
              <>
                <h2>Willkommen</h2>
                <p>Melden Sie sich an, um fortzufahren</p>
              </>
            )}
          </div>

          {error && (
            <div className="login-modal-error">
              {error}
            </div>
          )}

          {success && (
            <div className="login-modal-success">
              {success}
            </div>
          )}

          {isResetMode ? (
            <form onSubmit={handleResetSubmit} className="login-modal-form">
              {!isResetSent && (
                <div className="login-modal-form-group">
                  <label htmlFor="identifier">Benutzername oder E-Mail</label>
                  <input
                    type="text"
                    id="identifier"
                    name="identifier"
                    value={formData.identifier}
                    onChange={handleChange}
                    required
                    placeholder="Benutzername oder E-Mail eingeben"
                    disabled={isLoading}
                  />
                </div>
              )}

              <div className="login-modal-form-footer">
                <button 
                  type="button"
                  onClick={switchToLoginMode}
                  className="login-modal-link-button"
                >
                  Zurück zum Login
                </button>
              </div>

              {!isResetSent && (
                <button 
                  type="submit" 
                  className="login-modal-submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <span className="login-modal-spinner"></span>
                  ) : (
                    <>
                      <span>Passwort zurücksetzen</span>
                      <svg 
                        width="20" 
                        height="20" 
                        viewBox="0 0 24 24" 
                        fill="none" 
                        stroke="currentColor" 
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M9 18l6-6-6-6"/>
                      </svg>
                    </>
                  )}
                </button>
              )}
            </form>
          ) : (
            <form onSubmit={handleSubmit} className="login-modal-form">
              <div className="login-modal-form-group">
                <label htmlFor="identifier">Benutzername oder E-Mail</label>
                <input
                  type="text"
                  id="identifier"
                  name="identifier"
                  value={formData.identifier}
                  onChange={handleChange}
                  required
                  placeholder="Benutzername oder E-Mail eingeben"
                />
              </div>

              <div className="login-modal-form-group">
                <label htmlFor="password">Passwort</label>
                <div className="password-input-wrapper">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                    placeholder="••••••••"
                  />
                  <button 
                    type="button"
                    className="password-toggle-button"
                    onClick={() => setShowPassword(!showPassword)}
                    aria-label={showPassword ? "Passwort verbergen" : "Passwort anzeigen"}
                  >
                    {showPassword ? (
                      <svg 
                        width="20" 
                        height="20" 
                        viewBox="0 0 24 24" 
                        fill="none" 
                        stroke="currentColor" 
                        strokeWidth="2"
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                      >
                        <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24" />
                        <line x1="1" y1="1" x2="23" y2="23" />
                      </svg>
                    ) : (
                      <svg 
                        width="20" 
                        height="20" 
                        viewBox="0 0 24 24" 
                        fill="none" 
                        stroke="currentColor" 
                        strokeWidth="2"
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                      >
                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                        <circle cx="12" cy="12" r="3" />
                      </svg>
                    )}
                  </button>
                </div>
              </div>

              <div className="login-modal-form-footer">
                <button 
                  type="button"
                  onClick={switchToResetMode}
                  className="login-modal-link-button"
                >
                  Passwort vergessen?
                </button>
              </div>

              <button 
                type="submit" 
                className="login-modal-submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <span className="login-modal-spinner"></span>
                ) : (
                  <>
                    <span>Anmelden</span>
                    <svg 
                      width="20" 
                      height="20" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M9 18l6-6-6-6"/>
                    </svg>
                  </>
                )}
              </button>
            </form>
          )}
        </div>
      </div>
    );
};

export default LoginModal;